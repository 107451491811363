import React from 'react'
import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'
import { useMediaQuery } from '@mui/material';

const Layout = ({children}) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  return (
    <div style={{ display:"flex", flexDirection:"column"}}>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default Layout
