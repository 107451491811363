import React from 'react'
import { Box, useMediaQuery } from '@mui/material';
import './Services.css'
import ServicesList from './ServicesList';

const Services = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isTabletScreen = useMediaQuery('(min-width:601px) and (max-width:768px)');
  return (
    <Box sx={{display:"flex", flexDirection:"column", gap:"10px" , background:"#171717"}} minHeight={isSmallScreen?"calc(150vh)":isTabletScreen?"calc(100vh)":"calc(70vh)"} padding="1rem" width={isSmallScreen ? "calc(100% + 18rem)" : isTabletScreen? "calc(100% + 18rem)" : "calc(100%)"}>
      
      <div className="services">
        <h1>Services</h1>
      </div>
      
      <div className="services-sub">
        <div className='block1'>
          <h6>Our Services</h6>
          <h1>IT for Intelligence & Innovation</h1>
        </div>
        <h5>Paramba has over __ years of experience in Design, Technology and Consulting. We are dedicated to provide the best business solutions to the small and medium businesses at an affordable price. We understand the uniqueness of your entity so as your business needs. Paramba is a company specialized in the following services.</h5>
      </div>

      <div className="services-list">

            {/* Card 1 */}
            <ServicesList 
              // title={"Cloud Consulting"}
              faceBack={"We provide consulting, migration and management services for the largest public cloud platforms in the world."}
              faceFront={"Cloud Consulting"}
            />

            {/* Card 2 */}
            <ServicesList 
              // title={"Cloud Security"}
              faceBack={"Cloud computing is the delivery of hosted services, including software, hardware, and storage, over the Internet."}
              faceFront={"Cloud Security"}
            />

            {/* Card 3 */}
            <ServicesList 
              // title={"Cloud Migration"}
              faceBack={"Cloud migrations are not just a quick lift-shift your current infrastructure to the Cloud, it needs to follow a certain set."}
              faceFront={"Cloud Migration"}
            />

            {/* Card 4 */}
            <ServicesList 
              // title={"Cloud Management"}
              faceBack={"Cloud management is the process of maintaining oversight and administrative control of cloud computing products."}
              faceFront={"Cloud Management"}
            />

            {/* Card 5 */}
            <ServicesList 
              // title={"Software Testing"}
              faceBack={"Our QA lab has full range of outsourcing software testing services. We can provide you with any type of testing."}
              faceFront={"Software Testing"}
            />

            {/* Card 6 */}
            <ServicesList 
              // title={"Data Warehousing"}
              faceBack={"Data warehousing can lead up to a 50% decrease in cost. We provide Services to help companies gain control."}
              faceFront={"Data Warehousing"}
            />

            {/* Card 7 */}
            <ServicesList 
              // title={"Software Development"}
              faceBack={"We offers complete range of development services to cater to the needs of the industries that it focuses on."}
              faceFront={"Software Development"}
            />

            {/* Card 8 */}
            <ServicesList 
              // title={"IT Consulting"}
              faceBack={"We design winning strategies in meeting this need. We deliver value and performance through our mastery of digital advances."}
              faceFront={"IT Consulting"}
            />

        {/* <div className="cards">Hello</div>
        <div className="cards">Hello</div>
        <div className="cards">Hello</div> */}
      </div>
    </Box>
  )
}

export default Services
