import React from 'react'
import "./Services.css";

const ServicesList = ({faceBack,faceFront}) => {
  return (
    <div>
        <div className="card">
            <div className="face back">
            <div className="content">
                <span className="stars"></span>
                {/* <b className="desc">{title}</b> */}
                <p className="desc">
                {faceBack}
                </p>
            </div>
            </div>

            <div className="face front">
            <b>{faceFront}</b>
            </div>
        </div>
    </div>
  )
}

export default ServicesList
