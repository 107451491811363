import React, { useEffect, useState } from 'react';
import "./Navbar.css";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { maxWidth } from '@mui/system';

const Navbar = () => {
    const [activeLink, setActiveLink] = useState('home'); // default active link
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const handleClick = (link) => {
        setActiveLink(link);
        // You can add navigation logic here if needed
        navigate(`/${link}`);
    };

    useEffect(() => {
        navigate('/home');
    },[]);

    return (
        <div className="navbar">
            {/* Logo */}
            <div className='logo'>
                <img src='/assets/Paramba_Logo.png' alt='PARAMBA' />
                {isSmallScreen?'':
                    <div className='company'>
                        <h2 data-text='PARAMBA'>PARAMBA</h2>
                    </div>
                }
            </div>

            {/* Navbar */}
            <div className='navigation'>
                <ul>
                    <li className={`list ${activeLink === 'home' ? 'active' : ''}`} onClick={() => handleClick('home')}>
                        <a>
                            <span className='icon'>
                                <ion-icon name="home-outline"></ion-icon>
                            </span>
                            <span className='text'>Home</span>
                        </a>
                    </li>
                    <li className={`list ${activeLink === 'services' ? 'active' : ''}`} onClick={() => handleClick('services')}>
                        <a>
                            <span className='icon'>
                                <ion-icon name="extension-puzzle-outline"></ion-icon>
                            </span>
                            <span className='text'>Services</span>
                        </a>
                    </li>
                    <li className={`list ${activeLink === 'ourPartners' ? 'active' : ''}`} onClick={() => handleClick('ourPartners')}>
                        <a>
                            <span className='icon'>
                                <ion-icon name="people-outline"></ion-icon>
                            </span>
                            <span className='text' style={{marginTop:"10px"}}>Our Partners</span>
                        </a>
                    </li>
                    <li className={`list ${activeLink === 'contactUs' ? 'active' : ''}`} onClick={() => handleClick('contactUs')}>
                        <a>
                            <span className='icon'>
                                <ion-icon name="call-outline"></ion-icon>
                            </span>
                            <span className='text' style={{marginTop:"10px"}}>Contact Us</span>
                        </a>
                    </li>
                    <li className={`list ${activeLink === 'termsAndConditions' ? 'active' : ''}`} onClick={() => handleClick('termsAndConditions')}>
                        <a>
                            <span className='icon'>
                                <ion-icon name="document-text-outline"></ion-icon>
                            </span>
                            <span className='text' style={{marginTop:"10px"}}>Terms & Conditions</span>
                        </a>
                    </li>
                    <div className="indicator"></div>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
