import React from 'react'
// import Footer from './../Components/Footer/Footer';
import { Box, useMediaQuery } from '@mui/material';
import './Home.css'

const Home = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isTabletScreen = useMediaQuery('(min-width:601px) and (max-width:768px)');
  return (
    <Box minHeight={isSmallScreen?"calc(150vh)":isTabletScreen?"calc(100vh)":"calc(70vh)"}  width={isSmallScreen ? "calc(100% + 18rem)" : isTabletScreen? "calc(100% + 18rem)" : "calc(100%)"}>
    {/* <Footer /> */}
    <div className='bg'>
      <img src='/assets/Home.jpg' alt='Home'></img>
    </div>

    <div className="title">
      <h3>PARAMBA</h3>
      <h6>Empowering Innovation Through Technology</h6>
    </div>
    </Box>
  )
}

export default Home
