import React from 'react'
import { Box, useMediaQuery } from '@mui/material';
import './OurPartners.css';

const OurPartners = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isTabletScreen = useMediaQuery('(min-width:601px) and (max-width:768px)');
  return (
    <Box sx={{display:"flex", flexDirection:"column", gap:"10px", background:"#171717"}} minHeight={isSmallScreen?"calc(150vh)":isTabletScreen?"calc(100vh)":"calc(70vh)"} padding="1rem" width={isSmallScreen ? "calc(100% + 18rem)" : isTabletScreen? "calc(100% + 18rem)" : "calc(100%)"}>
      <div className="our-partners">
        <h1>Our Partners</h1>
        <p className='sub'>We do all kinds of software developments for our clients and provide managed services & implementation of Cloud Infrastructure all over the world.</p>
      </div>

      <div className="partners-sub">
        <div className="cards">
          <img src='/assets/client1.jpeg' alt='Nothing'></img>
          <h2>Daenerys Targaryen</h2>
          <h5>Mother of Dragons</h5>
        </div>
        <div className="cards">
          <img src='/assets/client2.jpeg' alt='Nothing'></img>
          <h2>Jon Snow</h2>
          <h5>King in the North</h5>
        </div>
        <div className="cards">
          <img src='/assets/client3.jpeg' alt='Nothing'></img>
          <h2>Daemon Targaryen</h2>
          <h5>The Rogue Prince</h5>
        </div>
      </div>

    </Box>
  )
}

export default OurPartners
