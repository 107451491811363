import Layout from './Components/Layout';
import { BrowserRouter,Routes, Route } from "react-router-dom";
import Home from './Pages/Home/Home';
import Services from './Pages/Services/Services';
import ContactUs from './Pages/ContactUs/ContactUs';
import OurPartners from './Pages/OurPartners/OurPartners';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/ourPartners" element={<OurPartners />} />
                        <Route path="/contactUs" element={<ContactUs />} />
                        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
                        
                    </Routes>
                </Layout>
            </BrowserRouter>
    </div>
  );
}

export default App;
