import React from 'react'
import "./Footer.css"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 Paramba Technologies. All rights reserved.</p>
        {/* <div className="footer-links">
          <a >Privacy Policy</a>
          <a >Terms of Service</a>
          <a >Contact Us</a>
        </div> */}
      </div>
    </footer>
  )
}

export default Footer
