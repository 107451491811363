import React from 'react'
import { Box, useMediaQuery, TextField, Button } from '@mui/material';
import "./ContactUs.css"

import styled from 'styled-components';

const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    color: #171717; /* Input text color */
    width: 70%;
    
    .MuiOutlinedInput-notchedOutline {
      border-color: #171717; /* Outline color */
      border-width: 2px; /* Outline thickness */
      border-radius: 20px;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #171717; /* Outline color on hover */
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #171717; /* Outline color when focused */
    }
  }

  .MuiInputLabel-root {
    color: #171717; /* Label color */
    &.Mui-focused {
      color: #171717; /* Label color when focused */
    }
  }
`;




const ContactUs = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isTabletScreen = useMediaQuery('(min-width:601px) and (max-width:768px)');
  return (
    <Box sx={{display:"flex", flexDirection:"column", gap:"10px", background:"#171717" }} minHeight={isSmallScreen?"calc(150vh)":isTabletScreen?"calc(100vh)":"calc(70vh)"} padding="1rem" width={isSmallScreen ? "calc(100% + 18rem)" : isTabletScreen? "calc(100% + 18rem)" : "calc(100%)"}>
      
      <div className="contact-us">
        <h1>Contact Us</h1>
        <p className='sub'>Reach Out for Support or Inquiries</p>
      </div>
      
      <div className="contact-sub">
        <div className='block1'>
          {/* <h6>Our Services</h6> */}
          <h1>Let's get in touch</h1>
          <img src="./assets/ContactUs.png" alt="" className="image" />
          <h5>Connect with us :</h5>
          <div className="social-media">
            <ul>
              <li><a href="#"><i class='bx bxl-facebook'></i></a></li>
              <li><a href="#"><i class='bx bxl-twitter'></i></a></li>
              <li><a href="#"><i class='bx bxl-youtube'></i></a></li>
              <li><a href="#"><i class='bx bxl-linkedin'></i></a></li>
            </ul>
          </div>
        </div>


        <div className='block2'>
          <h1>Contact Us</h1>
          <CustomTextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              size="small"
          />
          <CustomTextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              size="small"
          />
          <CustomTextField
              id="outlined-basic"
              label="Phone"
              variant="outlined"
              size="small"
          />
          {/* Text Area  */}
          <CustomTextField
              id="outlined-basic"
              label="Message"
              variant="outlined"
              size="small"
              multiline
              minRows={4}
          />
          
          <Button variant="contained"
            sx={{
              fontWeight: 600,
              letterSpacing:"2px",
              width:"30%",
              borderRadius:"20px",
              color:"#fec107",
              background:"#171717",
              '&:hover': {
                background: '#fec107', // Change background color on hover
                color: '#171717', 
                border: '2px solid #171717'    // Change text color on hover
              },
              
            }}
          >
            Submit
          </Button>

        </div>
      </div>
    </Box>
  )
}

export default ContactUs
