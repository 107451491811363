import React from 'react'
import { Box, useMediaQuery } from '@mui/material';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isTabletScreen = useMediaQuery('(min-width:601px) and (max-width:768px)');
  return (
    <Box minHeight={isSmallScreen?"calc(150vh)":isTabletScreen?"calc(100vh)":"calc(70vh)"}  width={isSmallScreen ? "calc(100% + 18rem)" : isTabletScreen? "calc(100% + 18rem)" : "calc(100%)"}>
        <div className="terms-conditions">
          <img src="/assets/TermsAndConditions.png" alt="Terms & Conditions" />
        </div>
        <div className="terms-conditions-text">
        Welcome to Paramba! These Terms and Conditions ("Terms") govern your use of our website and software products ("Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use our Services.

      
        1. Acceptance of Terms

        By accessing or using Paramba’s Services, you agree to these Terms, our Privacy Policy, and any other policies or guidelines referenced herein. We may update these Terms from time to time, and your continued use of our Services constitutes acceptance of any changes.

        2. Services Provided

        Paramba provides [brief description of your services, e.g., "cloud-based software solutions for project management"]. We reserve the right to modify, suspend, or discontinue any aspect of our Services at any time without notice.

        3. User Responsibilities

        Eligibility: You must be at least [minimum age] to use our Services. By using our Services, you represent that you are of legal age and have the capacity to enter into these Terms.
        Account Security: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account.
        Prohibited Uses: You agree not to use our Services for any unlawful purposes or in any way that could damage, disable, overburden, or impair our Services.
        4. Intellectual Property

        Ownership: All intellectual property rights in our Services and content are owned by Paramba or its licensors. You are granted a limited, non-exclusive, non-transferable license to use our Services in accordance with these Terms.
        Restrictions: You may not copy, modify, distribute, or create derivative works based on our Services without prior written consent from Paramba.
        5. Payment and Fees

        Subscription Fees: If applicable, you agree to pay all subscription fees and charges as described on our website or in the agreement between you and Paramba. Payments are non-refundable.
        Changes to Fees: We may change our fees at any time, and we will provide notice of any such changes in advance.
        6. Termination

        We reserve the right to terminate or suspend your access to our Services at our sole discretion, with or without cause, and with or without notice. You may terminate your use of our Services at any time by [instructions for termination, e.g., "contacting our support team"].

        7. Disclaimer of Warranties

        Our Services are provided "as is" and "as available," without any warranties of any kind, either express or implied. We do not warrant that our Services will be uninterrupted or error-free.

        8. Limitation of Liability

        To the maximum extent permitted by law, Paramba shall not be liable for any indirect, incidental, consequential, or punitive damages arising from your use of our Services. Our total liability for any claim related to our Services shall be limited to the amount you paid for the Services in the [time period, e.g., "12 months"] preceding the claim.

        9. Indemnification

        You agree to indemnify and hold harmless Paramba, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising out of your use of our Services or any violation of these Terms.

        10. Governing Law

        These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles. Any disputes arising under these Terms shall be resolved in the competent courts of [Your Jurisdiction].

        11. Contact Information

        If you have any questions or concerns about these Terms, please contact us at:

        Paramba
        [Address]
        [City, State, ZIP Code]
        [Email Address]
        [Phone Number]

        12. Miscellaneous

        Severability: If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.
        Entire Agreement: These Terms constitute the entire agreement between you and Paramba regarding the use of our Services and supersede all prior agreements and understandings.
        Thank you for choosing Paramba!
        </div>
    </Box>
  )
}

export default TermsAndConditions
